<template>
  <HXContentCard title="Nueva Sucursal" :toBack="{ name: 'Sucursales' }">
    <form @submit.prevent="saveData">
      <div class="row px-5 mt-4">
        <div class="col-md-6">
          <div>
            <label for=" " class="form-label">Nombre Sucursal</label>
            <input
              v-model="item.descripcion"
              required
              placeholder="Escriba el Nombre de la Sucursal..."
              type="text"
              class="form-control"
              id=" "
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label for=" " class="form-label">Departamento/Estado</label>
            <b-form-select
              @change.native="actualizaDepto($event)"
              v-model="deptoSelected"
              required
              text-field="descripcion"
              value-field="id"
              :options="ListDepto"
            >
              <template #first>
                <b-form-select-option
                  v-if="selected == null"
                  selected
                  :value="none"
                  disabled
                  >-- Debes seleccionar un país --</b-form-select-option
                >
                <b-form-select-option
                  v-if="selected != null"
                  selected
                  :value="none"
                  disabled
                  >-- seleccionar Departamento --</b-form-select-option
                >
              </template>
              <!-- <b-form-select-option ></b-form-select-option> -->
            </b-form-select>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-3">
            <label for=" " class="form-label">Empresa</label>
            <b-form-select
              :disabled="usuario.rol.nivel != 4 ? true : false"
              required
              v-model="item.EmpresaId"
              :options="ListEmpresas"
              text-field="nombre"
              value-field="id"
            ></b-form-select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label for=" " class="form-label">Ciudad/Municipio</label>
            <b-form-select
              required
              v-model="item.MunicipioId"
              :options="ListMunicipios"
              text-field="descripcion"
              value-field="id"
            >
              <template #first>
                <b-form-select-option selected :value="none" disabled
                  >-- Seleccionar Ciudad/Municipio --</b-form-select-option
                >
                <!-- <b-form-select-option v-if="selected!= null" selected :value="none" disabled>--  seleccionar Departamento --</b-form-select-option> -->
              </template>
            </b-form-select>
          </div>
        </div>

        <div class="col-md-6">
          <div class="mb-4">
            <label for=" " class="form-label">País</label>

            <b-form-select
              v-model="selected"
              @change.native="actualizaPais($event)"
              required
              value-field="id"
              text-field="descripcion"
              :options="ListPais"
            >
              <template #first>
                <b-form-select-option :value="none" disabled
                  >-- Selecciona un país --</b-form-select-option
                >
              </template>
            </b-form-select>
          </div>
        </div>
      </div>
      <div class="text-right px-5 pb-4 d-flex justify-content-end">
        <vs-button icon animation-type="vertical" type="submit">
          <i class="fas fa-save pr-1"></i>
          Guardar Sucursal
          <template #animate>
            <i class="fas fa-save"></i>
          </template>
        </vs-button>
      </div>
    </form>
  </HXContentCard>
</template>
<script>
export default {
  data() {
    return {
      item: { descripcion: "", EmpresaId: "", MunicipioId: "", Estado: 1 },
      ListPais: [],
      ListDepto: [],
      ListMunicipios: [],
      ListEmpresas: [],
      pais: "",
      depto: "",
      selected: null,
      deptoSelected: null,
      ciudadSelected: null,
      municipioSelected: null,
      empresaSelected: null,
      usuario: {},
    };
  },
  async mounted() {
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    console.log("usuario ", this.usuario);
    this.item.EmpresaId = this.usuario.empresa.id;

    await this.getPais();
    await this.getEmpresas();
  },
  methods: {
    async actualizaDepto(e) {
      console.log(e.target.value);
      this.depto = e.target.value;
      await this.getMunicipio(this.depto);
    },
    async actualizaPais(e) {
      console.log(e.target.value);
      this.pais = e.target.value;
      await this.getDepartamento(this.pais);
    },
    async getEmpresas() {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "Empresa/ListEmpresa/",
        });
        this.ListEmpresas = res;

        console.log("list", this.ListEmpresas);
      } catch (error) {
        this.ListEmpresas = {};
        console.log("err", error);
      }
    },
    async getPais() {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "Pais/List/",
        });
        this.ListPais = res;

        console.log("list", this.ListPais);
      } catch (error) {
        this.ListPais = {};
        console.log("err", error);
      }
    },
    async getDepartamento(pais) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "DepartamentoPais/ListByPais/" + pais,
        });
        this.ListDepto = res;

        console.log("ListDepto", this.ListDepto);
      } catch (error) {
        this.ListDepto = {};
        console.log("err", error);
      }
    },
    async getMunicipio(departamentoId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Municipio/ListByDepartamento/" + departamentoId,
        });
        this.ListMunicipios = res;

        console.log("ListDepto", this.ListDepto);
      } catch (error) {
        this.ListMunicipios = {};
        console.log("err", error);
      }
    },
    async saveData() {
      console.log("saving", this.item);
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "Sucursal/Create/",
          data: this.item,
        });
        console.log("post empresas...", res);
        if (res) this.$router.push("/helex/Sucursales");
        else alert("Verificar");
      } catch (error) {
        this.item = {};
        console.log("err", error);
      }
    },
  },
};
</script>
